import "./basic_info.css";
import DescriptionCarousel from "../card_display/description-carousel";

function Basic_info() {
  return (
    <div className="basic-info-display_carousel">
      <DescriptionCarousel />
    </div>
  );
}

export default Basic_info;

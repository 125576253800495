import "./header.css";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface props {
  setBuyTab: any;
  buyTab: boolean;
}

function Header({ setBuyTab, buyTab }: props) {
  const { t } = useTranslation();
  const [blackHeader, setBlackHeader] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > document.body.scrollHeight / 10) {
      setBlackHeader(true);
    } else {
      setBlackHeader(false);
    }
  };

  const handleBuyBar = () => setBuyTab(!buyTab);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <header style={{ backgroundColor: blackHeader ? "#111111" : "" }}>
      <div>
        <a className="logo">{t("game_name")}</a>
      </div>
      <nav>
        <button
          onClick={handleBuyBar}
          className="text-3xl p-2 bg-blue-800 hover:bg-blue-500 transition-all hover:shadow-md font-black rounded-xl px-4 shadow-md"
        >
          {t("buy")}
        </button>
        {/* <Link to="/subasta" className="std-button">
          Subastas
          <img src={One} className="std-button-notification" />
        </Link> */}
      </nav>
    </header>
  );
}

export default Header;

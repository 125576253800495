import GameBox from "../../images/producto2.png";
import { useTranslation } from "react-i18next";
import "./Obtain.css";

interface props {
  openBuyTab: any;
}
function Obtain({ openBuyTab }: props) {
  const { t } = useTranslation();
  const isPhone = window.innerWidth < 768;
  return (
    <div className="p-4 py-24">
      <div className="flex w-fit mx-auto gap-6 my-10 p-6 flex-col xl:flex-row items-center">
        <div className="w-1/3" />
        <div className="text-3xl flex flex-col max-w-4xl">
          <h1 className="text-5xl py-3">{t("what_is_it")}</h1>
          {t("intro")}
          {!isPhone && (
            <button
              onClick={openBuyTab}
              className="text-2xl bg-blue-500 text-white rounded-xl font-bold p-4 w-fit mt-10"
            >
              {t("buy")}
            </button>
          )}
        </div>
        <div className="py-4 px-10">
          <img src={GameBox} alt="" className="w-56 mx-auto h-auto" />
        </div>
      </div>
    </div>
  );
}
export default Obtain;

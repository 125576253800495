import "./styles/App.css";
import Header from "./components/header/header";
import ImageTop from "./components/image_top/image";
import { Text, TextWitMerchant } from "./components/introductory_text/Text";
import DescriptionCarousel from "./components/card_display/description-carousel";
import { useState } from "react";
import NavBar from "./components/buy_tab/buy-tab";
import ImageBottom from "./components/image_bot/Image_bottom";
import texts from "./content/texts";
import Basic_info from "./components/basic_info_display/Basic_info";
import Footer from "./components/footer/Footer";
import Spinner from "./components/spinner/Spinner";
import Contact from "./components/Contact/Contact";
import Crowd from "./components/CrowdCampain/Crowd";
import { useTranslation } from "react-i18next";
import Obtain from "./components/obtain/Obtain";
import { VideoPlayer } from "./components/video/Video";

function Home() {
  const [loaded, setLoaded] = useState(false);
  const [buyTab, setBuyTab] = useState(false);
  const [contactEnabled, setContactEnabled] = useState(false);
  const [crowdEnabled, setCrowdEnabled] = useState(true);
  const { t } = useTranslation();
  const Playit = () => {
    var audio = new Audio("theme.mp3");
    audio.play();
  };

  return (
    <div className="main">
      {contactEnabled && <Contact setContactEnabled={setContactEnabled} />}
      {/* {crowdEnabled && (
        <Crowd setCrowdEnabled={setCrowdEnabled} setBuyTab={setBuyTab} />
      )} */}
      <NavBar setBuyTab={setBuyTab} buyTab={buyTab} />
      <Header setBuyTab={setBuyTab} buyTab={buyTab} />
      <ImageTop setLoaded={setLoaded} />
      {!loaded && <Spinner />}
      {loaded && (
        <>
          <Obtain openBuyTab={() => setBuyTab(true)} />
          <ImageBottom />
          <TextWitMerchant text={t("description")} />
          <Basic_info />
          <div className="h-48" />
          <VideoPlayer />
          <Footer setContactEnabled={setContactEnabled} />
        </>
      )}
    </div>
  );
}

export default Home;

import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import "./Video.css";
import { motion } from "framer-motion";

export const VideoPlayer = () => {
  const { t } = useTranslation();
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlayClick = () => {
    if (videoRef.current) {
      videoRef.current.play();
    }
  };

  const handlePlay = () => {
    setIsPlaying(true);
  };

  const handlePause = () => {
    setIsPlaying(false);
  };

  return (
    <motion.div
      className="flex w-full justify-center"
      initial={{ opacity: 0, scale: 0.5 }}
      whileInView={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.3 }}
    >
      <div
        className={`w-full flex flex-col items-center justify-center video_wrapper p-10 md:p-32 lg:px-48 lg:py-32 ${
          isPlaying ? "playing" : ""
        }`}
      >
        <h1 className="text-6xl py-6 font-hashiban text-black">
          {t("how_to_play")}
        </h1>
        <div className="video_container">
          <video
            ref={videoRef}
            controls
            className="w-full"
            onPlay={handlePlay}
            onPause={handlePause}
          >
            <source src="/video_es.mp4" type="video/mp4" />
          </video>
          {!isPlaying && (
            <div className="play-button" onClick={handlePlayClick}></div>
          )}
        </div>
      </div>
    </motion.div>
  );
};
